import React, { createContext, useState, useContext, useEffect } from 'react';
import * as apiService from '../services/api';

interface User {
  id: number;
  name: string;
  email: string;
  is_admin: boolean;
}

interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  register: (name: string, email: string, password: string, isAdmin: boolean) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        if (!parsedUser.id || !parsedUser.email || !parsedUser.name) {
          throw new Error('Invalid user data');
        }
        setUser(parsedUser);
      } catch (error) {
        console.error('Error parsing stored user:', error);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
      }
    }
  }, []);

  const login = async (email: string, password: string) => {
    try {
      console.log('Login attempt:', { email }); // Don't log password
      const response = await apiService.login(email, password);
      const { token, user: userData } = response.data;
      
      if (!userData || !userData.id || !userData.email || !userData.name) {
        throw new Error('Invalid user data received from server');
      }

      // Ensure we're using the name from the server response
      const userObj = {
        id: userData.id,
        name: userData.name, // Use the name from the server response
        email: userData.email,
        is_admin: userData.is_admin
      };

      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(userObj));
      setUser(userObj);
    } catch (error: any) {
      console.error('Login error:', error);
      throw new Error(error.response?.data?.message || 'Failed to login');
    }
  };

  const register = async (name: string, email: string, password: string, isAdmin: boolean) => {
    try {
      const response = await apiService.register(name, email, password, isAdmin);
      const { token, user: userData } = response.data;
      
      // Ensure we're using the registered name
      const userObj = {
        id: userData.id,
        name: name, // Use the name provided during registration
        email: userData.email,
        is_admin: userData.is_admin
      };

      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(userObj));
      setUser(userObj);
    } catch (error: any) {
      console.error('Registration error:', error);
      throw new Error(error.response?.data?.message || 'Failed to register');
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{
      user,
      isAuthenticated: !!user,
      login,
      register,
      logout,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};