import React from 'react';
import { Edit, Trash2 } from 'lucide-react';
import { Course } from '../types';

interface CoursesTableProps {
  courses: Course[];
  onDelete: (id: number) => Promise<void>;
}

const CoursesTable: React.FC<CoursesTableProps> = ({ courses, onDelete }) => {
  if (!courses || !Array.isArray(courses)) {
    return <div>No courses available</div>;
  }

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
      <table className="w-full">
        <thead className="bg-gray-700">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Title</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Modules</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-700">
          {courses.map((course) => (
            <tr key={course.id}>
              <td className="px-6 py-4 whitespace-nowrap">{course.title}</td>
              <td className="px-6 py-4 whitespace-nowrap">{course.modules?.length || 0}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button className="text-blue-400 hover:text-blue-300 mr-3">
                  <Edit className="h-5 w-5" />
                </button>
                <button 
                  onClick={() => onDelete(course.id)}
                  className="text-red-400 hover:text-red-300"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CoursesTable;