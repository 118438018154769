// src/components/AddCourseForm.tsx
import React, { useState, useRef } from 'react';
import { Plus, X, Upload, Loader } from 'lucide-react';

interface ModuleFile {
  file: File;
  title: string;
  progress: number;
}

interface AddCourseFormProps {
  onSubmit: (formData: FormData) => Promise<void>;
  onCancel: () => void;
}

const AddCourseForm: React.FC<AddCourseFormProps> = ({ onSubmit, onCancel }) => {
  const [courseTitle, setCourseTitle] = useState('');
  const [moduleFiles, setModuleFiles] = useState<ModuleFile[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const allowedTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'video/mp4',
    'video/webm',
    'audio/mpeg',
    'audio/wav'
  ];

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    const newFiles = Array.from(files).map(file => {
      if (!allowedTypes.includes(file.type)) {
        setError(`File type not supported: ${file.name}`);
        return null;
      }
      return {
        file,
        title: file.name.split('.')[0], // Default title is filename without extension
        progress: 0
      };
    }).filter((file): file is ModuleFile => file !== null);

    setModuleFiles(prev => [...prev, ...newFiles]);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset input
    }
  };

  const handleRemoveModule = (index: number) => {
    setModuleFiles(prev => prev.filter((_, i) => i !== index));
  };

  const handleModuleTitleChange = (index: number, newTitle: string) => {
    setModuleFiles(prev => prev.map((module, i) => 
      i === index ? { ...module, title: newTitle } : module
    ));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!courseTitle.trim()) {
      setError('Course title is required');
      return;
    }

    if (moduleFiles.length === 0) {
      setError('At least one module is required');
      return;
    }

    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append('title', courseTitle);

      // Add module data
      const moduleData = moduleFiles.map((module, index) => ({
        title: module.title,
        order: index + 1
      }));
      formData.append('moduleData', JSON.stringify(moduleData));

      // Add files
      moduleFiles.forEach((module, index) => {
        formData.append('moduleFiles', module.file);
      });

      await onSubmit(formData);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to create course');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-8">
      <h2 className="text-2xl font-semibold mb-4">Upload New Course</h2>
      
      {error && (
        <div className="mb-4 p-3 bg-red-500/20 border border-red-500 rounded-lg text-red-100">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="title" className="block text-sm font-medium mb-1">
            Course Title
          </label>
          <input
            type="text"
            id="title"
            value={courseTitle}
            onChange={(e) => setCourseTitle(e.target.value)}
            className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
            required
            disabled={isUploading}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Course Modules</label>
          
          {moduleFiles.map((module, index) => (
            <div key={index} className="mb-3 p-3 bg-gray-700 rounded-lg">
              <div className="flex items-center justify-between mb-2">
                <input
                  type="text"
                  value={module.title}
                  onChange={(e) => handleModuleTitleChange(index, e.target.value)}
                  className="flex-1 p-2 bg-gray-600 border border-gray-500 rounded-md focus:outline-none focus:border-blue-500 mr-2"
                  placeholder="Module title"
                  required
                  disabled={isUploading}
                />
                <button
                  type="button"
                  onClick={() => handleRemoveModule(index)}
                  className="text-red-400 hover:text-red-300 p-1"
                  disabled={isUploading}
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
              <div className="text-sm text-gray-400">
                {module.file.name} ({(module.file.size / 1024 / 1024).toFixed(2)} MB)
              </div>
            </div>
          ))}

          <div className="mt-3">
            <input
              ref={fileInputRef}
              type="file"
              onChange={handleFileSelect}
              className="hidden"
              multiple
              accept=".pdf,.pptx,.mp4,.webm,.mp3,.wav"
              disabled={isUploading}
            />
            <button
              type="button"
              onClick={() => fileInputRef.current?.click()}
              className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-300 flex items-center"
              disabled={isUploading}
            >
              <Upload className="mr-2" /> Add Module Files
            </button>
          </div>
        </div>

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-500 transition-colors"
            disabled={isUploading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-500 transition-colors flex items-center"
            disabled={isUploading}
          >
            {isUploading ? (
              <>
                <Loader className="animate-spin -ml-1 mr-2 h-5 w-5" />
                Uploading...
              </>
            ) : (
              <>
                <Plus className="mr-2" />
                Create Course
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCourseForm;