import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Check, X, Home } from 'lucide-react';

interface QuestionResult {
  question_text: string;
  options: string[];
  correct_answer: number;
  user_answer: number | null;
}

interface ExamResultsState {
  examName: string;
  score: number;
  passed: boolean;
  passScore: number;
  totalQuestions: number;
  correctAnswers: number;
  questions: QuestionResult[];
}

const ExamResults: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const results = location.state as ExamResultsState;

  if (!results) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-600 text-white p-4 rounded-lg mb-4">
          No results data available
        </div>
        <button
          onClick={() => navigate('/user-dashboard')}
          className="bg-blue-600 text-white py-2 px-6 rounded-lg text-lg hover:bg-blue-500 transition duration-300 flex items-center"
        >
          <Home className="mr-2" /> Return to Dashboard
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-gray-800 rounded-lg shadow-lg p-6 mb-8">
        <h1 className="text-3xl font-bold mb-6 text-center">{results.examName} Results</h1>
        
        <div className="text-center mb-8">
          <div className="text-6xl font-bold mb-4">
            {results.score.toFixed(1)}%
          </div>
          <div className={`text-xl font-semibold ${
            results.passed ? 'text-green-400' : 'text-red-400'
          }`}>
            {results.passed ? (
              <span className="flex items-center justify-center">
                <Check className="mr-2" /> Passed
              </span>
            ) : (
              <span className="flex items-center justify-center">
                <X className="mr-2" /> Failed
              </span>
            )}
          </div>
          <div className="text-gray-400 mt-2">
            Pass Score: {results.passScore}%
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
          <div className="bg-gray-700 p-4 rounded-lg">
            <h3 className="text-lg font-medium mb-2">Total Questions</h3>
            <p className="text-3xl font-bold">{results.totalQuestions}</p>
          </div>
          <div className="bg-gray-700 p-4 rounded-lg">
            <h3 className="text-lg font-medium mb-2">Correct Answers</h3>
            <p className="text-3xl font-bold">{results.correctAnswers}</p>
          </div>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Question Review</h2>
          <div className="space-y-6">
            {results.questions.map((question, index) => (
              <div key={index} className="bg-gray-700 rounded-lg p-4">
                <p className="text-lg mb-3">{question.question_text}</p>
                <div className="space-y-2">
                  {question.options.map((option, optIndex) => (
                    <div
                      key={optIndex}
                      className={`p-3 rounded-lg ${
                        optIndex === question.correct_answer
                          ? 'bg-green-600/20 border border-green-500'
                          : optIndex === question.user_answer
                          ? 'bg-red-600/20 border border-red-500'
                          : 'bg-gray-600'
                      }`}
                    >
                      {option}
                      {optIndex === question.correct_answer && (
                        <span className="ml-2 text-green-400">
                          <Check className="inline-block h-4 w-4" /> Correct Answer
                        </span>
                      )}
                      {optIndex === question.user_answer && optIndex !== question.correct_answer && (
                        <span className="ml-2 text-red-400">
                          <X className="inline-block h-4 w-4" /> Your Answer
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-8 text-center">
          <button
            onClick={() => navigate('/user-dashboard')}
            className="bg-blue-600 text-white py-2 px-6 rounded-lg text-lg hover:bg-blue-500 transition duration-300 flex items-center mx-auto"
          >
            <Home className="mr-2" /> Return to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamResults;