import React, { useState, useEffect } from 'react';
import { Plus, X } from 'lucide-react';
import { Exam, NewExam } from '../types';

interface EditExamFormProps {
  exam: Exam;
  onSubmit: (examId: number, updatedExam: NewExam) => void;
  onCancel: () => void;
}

const EditExamForm: React.FC<EditExamFormProps> = ({ exam, onSubmit, onCancel }) => {
  const [editedExam, setEditedExam] = useState<NewExam>({
    name: exam.name,
    time_limit: exam.time_limit,
    pass_score: exam.pass_score,
    total_questions: exam.total_questions,
    questions: exam.questions.map(q => ({
      question_text: q.question_text,
      options: q.options,
      correct_answer: q.correct_answer
    })),
  });

  const [errors, setErrors] = useState<{
    total_questions?: string;
    questions?: string;
  }>({});

  useEffect(() => {
    validateTotalQuestions(editedExam.total_questions);
  }, [editedExam.questions.length, editedExam.total_questions]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numValue = name === 'name' ? value : Math.max(1, parseInt(value) || 0);
    
    setEditedExam(prev => ({
      ...prev,
      [name]: numValue
    }));

    if (name === 'total_questions') {
      validateTotalQuestions(parseInt(value));
    }
  };

  const validateTotalQuestions = (value: number) => {
    const newErrors = { ...errors };
    
    if (value < 1) {
      newErrors.total_questions = 'Must have at least 1 question';
    } else if (editedExam.questions.length > 0 && value > editedExam.questions.length) {
      newErrors.total_questions = `Cannot exceed question pool size (${editedExam.questions.length})`;
    } else {
      delete newErrors.total_questions;
    }
    
    setErrors(newErrors);
  };

  const handleAddQuestion = () => {
    setEditedExam(prev => ({
      ...prev,
      questions: [...prev.questions, { question_text: '', options: ['', ''], correct_answer: 0 }],
    }));
    validateTotalQuestions(editedExam.total_questions);
  };

  const handleQuestionChange = (index: number, field: string, value: string | number | string[]) => {
    setEditedExam(prev => ({
      ...prev,
      questions: prev.questions.map((q, i) => 
        i === index ? { ...q, [field]: value } : q
      ),
    }));
  };

  const handleAddOption = (questionIndex: number) => {
    setEditedExam(prev => ({
      ...prev,
      questions: prev.questions.map((q, i) => 
        i === questionIndex 
          ? { ...q, options: [...q.options, ''] }
          : q
      ),
    }));
  };

  const handleRemoveOption = (questionIndex: number, optionIndex: number) => {
    setEditedExam(prev => ({
      ...prev,
      questions: prev.questions.map((q, i) => {
        if (i !== questionIndex) return q;
        
        const newOptions = q.options.filter((_, idx) => idx !== optionIndex);
        let newCorrectAnswer = q.correct_answer;
        
        if (optionIndex <= q.correct_answer && newCorrectAnswer > 0) {
          newCorrectAnswer--;
        }
        
        return {
          ...q,
          options: newOptions,
          correct_answer: Math.min(newCorrectAnswer, newOptions.length - 1)
        };
      }),
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (Object.keys(errors).length > 0) {
      return;
    }

    if (editedExam.questions.length < editedExam.total_questions) {
      setErrors(prev => ({
        ...prev,
        questions: 'Not enough questions in the pool'
      }));
      return;
    }

    const validatedExam: NewExam = {
      name: editedExam.name,
      time_limit: Math.max(1, parseInt(String(editedExam.time_limit)) || 60),
      pass_score: Math.max(0, Math.min(100, parseInt(String(editedExam.pass_score)) || 70)),
      total_questions: Math.min(editedExam.total_questions, editedExam.questions.length),
      questions: editedExam.questions.map(q => ({
        question_text: q.question_text,
        options: q.options.filter(opt => opt.trim() !== ''),
        correct_answer: Math.min(q.correct_answer, q.options.filter(opt => opt.trim() !== '').length - 1)
      }))
    };
    
    onSubmit(exam.id, validatedExam);
  };

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-8">
      <h2 className="text-2xl font-semibold mb-4">Edit Exam</h2>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium mb-1">Exam Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={editedExam.name}
              onChange={handleInputChange}
              className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="time_limit" className="block text-sm font-medium mb-1">Time Limit (minutes)</label>
            <input
              type="number"
              id="time_limit"
              name="time_limit"
              min="1"
              max="180"
              value={editedExam.time_limit}
              onChange={handleInputChange}
              className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="pass_score" className="block text-sm font-medium mb-1">Pass Score (%)</label>
            <input
              type="number"
              id="pass_score"
              name="pass_score"
              min="0"
              max="100"
              value={editedExam.pass_score}
              onChange={handleInputChange}
              className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="total_questions" className="block text-sm font-medium mb-1">Questions Per Exam</label>
            <input
              type="number"
              id="total_questions"
              name="total_questions"
              min="1"
              value={editedExam.total_questions}
              onChange={handleInputChange}
              className={`w-full p-2 bg-gray-700 border ${
                errors.total_questions ? 'border-red-500' : 'border-gray-600'
              } rounded-md focus:outline-none focus:border-blue-500`}
              required
            />
            {errors.total_questions && (
              <p className="mt-1 text-sm text-red-500">{errors.total_questions}</p>
            )}
          </div>
        </div>

        <h3 className="text-xl font-semibold mb-2">Question Pool</h3>
        {errors.questions && (
          <p className="mb-2 text-sm text-red-500">{errors.questions}</p>
        )}
        
        {editedExam.questions.map((q, questionIndex) => (
          <div key={questionIndex} className="mb-4 p-4 bg-gray-700 rounded-lg">
            <input
              type="text"
              value={q.question_text}
              onChange={(e) => handleQuestionChange(questionIndex, 'question_text', e.target.value)}
              className="w-full p-2 mb-2 bg-gray-600 border border-gray-500 rounded-md focus:outline-none focus:border-blue-500"
              placeholder="Question text"
              required
            />
            {q.options.map((opt, optIndex) => (
              <div key={optIndex} className="flex mb-2">
                <input
                  type="text"
                  value={opt}
                  onChange={(e) => {
                    const newOptions = [...q.options];
                    newOptions[optIndex] = e.target.value;
                    handleQuestionChange(questionIndex, 'options', newOptions);
                  }}
                  className="flex-1 p-2 bg-gray-600 border border-gray-500 rounded-md focus:outline-none focus:border-blue-500"
                  placeholder={`Option ${optIndex + 1}`}
                  required
                />
                <button
                  type="button"
                  onClick={() => handleRemoveOption(questionIndex, optIndex)}
                  className="ml-2 p-2 text-red-400 hover:text-red-300"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            ))}
            <div className="flex justify-between items-center mt-2">
              <button
                type="button"
                onClick={() => handleAddOption(questionIndex)}
                className="text-blue-400 hover:text-blue-300 flex items-center"
              >
                <Plus className="h-4 w-4 mr-1" /> Add Option
              </button>
              <select
                value={q.correct_answer}
                onChange={(e) => handleQuestionChange(questionIndex, 'correct_answer', parseInt(e.target.value))}
                className="p-2 bg-gray-600 border border-gray-500 rounded-md focus:outline-none focus:border-blue-500"
                required
              >
                {q.options.map((_, i) => (
                  <option key={i} value={i}>Correct Answer: Option {i + 1}</option>
                ))}
              </select>
            </div>
          </div>
        ))}
        
        <button
          type="button"
          onClick={handleAddQuestion}
          className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-300 mb-4"
        >
          <Plus className="inline-block mr-2" /> Add Question to Pool
        </button>

        <div className="flex justify-end">
          <button
            type="button"
            onClick={onCancel}
            className="bg-gray-600 text-white py-2 px-4 rounded hover:bg-gray-500 transition duration-300 mr-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-500 transition duration-300"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditExamForm;