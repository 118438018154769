import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookOpen, BarChart2, BookOpenCheck, ExternalLink } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { getExams, getCourses, getUserStats } from '../services/api';
import { Exam, Course, UserStats } from '../types';
import Logo from './Logo';
import CourseViewer from './CourseViewer';

const UserDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [availableExams, setAvailableExams] = useState<Exam[]>([]);
  const [myCourses, setMyCourses] = useState<Course[]>([]);
  const [userStats, setUserStats] = useState<UserStats>({
    examsTaken: 0,
    averageScore: 0,
    highestScore: 0,
    lowestScore: 0
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        if (!user?.id) {
          throw new Error('User not found');
        }
        
        const [examsResponse, coursesResponse, statsResponse] = await Promise.all([
          getExams(),
          getCourses(),
          getUserStats(user.id)
        ]);

        setAvailableExams(examsResponse.data || []);
        setMyCourses(coursesResponse.data || []);
        setUserStats({
          examsTaken: Number(statsResponse.data.examsTaken) || 0,
          averageScore: Number(statsResponse.data.averageScore) || 0,
          highestScore: Number(statsResponse.data.highestScore) || 0,
          lowestScore: Number(statsResponse.data.lowestScore) || 0
        });
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    if (user?.id) {
      fetchData();
    }
  }, [user?.id]);

  if (selectedCourse) {
    return (
      <CourseViewer 
        course={selectedCourse} 
        onClose={() => setSelectedCourse(null)}
      />
    );
  }

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-lg">Loading dashboard...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center justify-between mb-8">
        <Logo className="h-12" />
        <div>
          <h1 className="text-3xl font-bold text-blue-400" style={{ fontFamily: 'Orbitron, sans-serif' }}>
            Mascott Learning Hub
          </h1>
          <h2 className="text-xl text-gray-400 mt-2">Welcome, {user?.name || 'User'}!</h2>
        </div>
      </div>

      {error && (
        <div className="bg-red-500 text-white p-4 rounded-lg mb-4">
          {error}
        </div>
      )}
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <BookOpen className="mr-2" /> Available Exams
          </h2>
          <div className="space-y-4">
            {availableExams.length === 0 ? (
              <div className="bg-gray-800 p-4 rounded-lg">
                No exams available at this time.
              </div>
            ) : (
              availableExams.map((exam) => (
                <div key={exam.id} className="bg-gray-800 p-4 rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-2">{exam.name}</h3>
                  <p className="text-gray-400 mb-2">Time Limit: {exam.time_limit} minutes</p>
                  <p className="text-gray-400 mb-4">Questions: {exam.total_questions}</p>
                  <button
                    onClick={() => navigate(`/exam/${exam.id}`)}
                    className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-300 flex items-center"
                  >
                    Start Exam <ExternalLink className="ml-2 h-4 w-4" />
                  </button>
                </div>
              ))
            )}
          </div>
        </div>
        
        <div>
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <BarChart2 className="mr-2" /> Your Stats
          </h2>
          <div className="bg-gray-800 p-6 rounded-lg shadow mb-8">
            <div className="mb-4">
              <p className="text-lg font-semibold">Exams Taken</p>
              <p className="text-3xl font-bold text-blue-400">{userStats.examsTaken}</p>
            </div>
            <div className="mb-4">
              <p className="text-lg font-semibold">Average Score</p>
              <p className="text-3xl font-bold text-green-400">
                {userStats.averageScore.toFixed(1)}%
              </p>
            </div>
            <div className="mb-4">
              <p className="text-lg font-semibold">Highest Score</p>
              <p className="text-3xl font-bold text-yellow-400">
                {userStats.highestScore.toFixed(1)}%
              </p>
            </div>
            <div>
              <p className="text-lg font-semibold">Lowest Score</p>
              <p className="text-3xl font-bold text-red-400">
                {userStats.lowestScore.toFixed(1)}%
              </p>
            </div>
          </div>

          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <BookOpenCheck className="mr-2" /> My Courses
          </h2>
          <div className="space-y-4">
            {myCourses.length === 0 ? (
              <div className="bg-gray-800 p-4 rounded-lg">
                No courses available at this time.
              </div>
            ) : (
              myCourses.map((course) => (
                <div key={course.id} className="bg-gray-800 p-4 rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-2">{course.title}</h3>
                  <p className="text-gray-400 mb-2">
                    Modules: {course.modules?.length || 0}
                  </p>
                  <button
                    onClick={() => setSelectedCourse(course)}
                    className="bg-purple-600 text-white py-2 px-4 rounded hover:bg-purple-500 transition duration-300 flex items-center"
                  >
                    View Course <ExternalLink className="ml-2 h-4 w-4" />
                  </button>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;