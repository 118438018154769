import axios from 'axios';
import { 
  NewExam, 
  Exam, 
  Course, 
  UserStats, 
  AuthResponse, 
  ExamSubmissionData,
  ExamSubmitResponse,
  UserListItem,
  DetailedUserStats
} from '../types';

export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:4005/api';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 300000 // 5 minutes timeout
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Don't set Content-Type for FormData (file uploads)
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    }

    console.log('Outgoing Request:', {
      url: `${config.baseURL}${config.url}`,
      method: config.method?.toUpperCase(),
      headers: config.headers,
      data: config.data instanceof FormData ? 'FormData' : config.data
    });

    return config;
  },
  (error) => {
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    console.log('Response Received:', {
      status: response.status,
      statusText: response.statusText,
      data: response.data
    });
    return response;
  },
  (error) => {
    console.error('API Error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });

    if (error.response?.status === 401 && error.response?.data?.expired) {
      // Clear local storage
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      
      // Show message to user
      alert('Your session has expired. Please log in again.');
      
      // Redirect to login
      window.location.href = '/login';
      return Promise.reject(error);
    }

    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Auth endpoints
export const login = (email: string, password: string) => {
  return api.post<AuthResponse>('/auth/login', { email, password });
};

export const register = (name: string, email: string, password: string, isAdmin: boolean) => {
  return api.post<AuthResponse>('/auth/register', { 
    name, 
    email, 
    password, 
    is_admin: isAdmin 
  });
};

// User endpoints
export const getUserStats = (userId: number) => {
  return api.get<UserStats>(`/users/${userId}/stats`);
};

export const getAllUsers = () => {
  return api.get<UserListItem[]>('/users');
};

export const deleteUser = (userId: number) => {
  return api.delete(`/users/${userId}`);
};

export const getUserDetailedStats = (userId: number) => {
  return api.get<DetailedUserStats>(`/users/${userId}/stats`);
};

// Exam endpoints
export const getExams = () => {
  return api.get<Exam[]>('/exams');
};

export const getExam = (id: number) => {
  return api.get<Exam>(`/exams/${id}`);
};

export const createExam = (examData: NewExam) => {
  return api.post<{ message: string; exam: Exam }>('/exams', examData);
};

export const updateExam = (id: number, examData: NewExam) => {
  return api.put<{ message: string; exam: Exam }>(`/exams/${id}`, examData);
};

export const deleteExam = (id: number) => {
  return api.delete(`/exams/${id}`);
};

export const submitExam = (examId: number, submissionData: ExamSubmissionData) => {
  return api.post<ExamSubmitResponse>(`/exams/${examId}/submit`, submissionData);
};

// Course endpoints
export const getCourses = () => {
  return api.get<Course[]>('/courses');
};

export const getCourse = (id: number) => {
  return api.get<Course>(`/courses/${id}`);
};

export const createCourse = (formData: FormData) => {
  return api.post<{ message: string; course: Course }>('/courses', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const updateCourse = (id: number, formData: FormData) => {
  return api.put<{ message: string; course: Course }>(`/courses/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteCourse = (id: number) => {
  return api.delete(`/courses/${id}`);
};

// Course enrollment endpoints
export const enrollInCourse = (courseId: number) => {
  return api.post(`/courses/${courseId}/enroll`);
};

export const updateCourseProgress = (courseId: number, progress: number) => {
  return api.put(`/courses/${courseId}/progress`, { progress });
};

export const completeCourse = (courseId: number) => {
  return api.put(`/courses/${courseId}/complete`);
};

export default api;