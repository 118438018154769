import React from 'react';

interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className = "" }) => (
  <img 
    src="/mascott-logo.png" 
    alt="Mascott Equipment Company" 
    className={`w-auto ${className}`}
  />
);

export default Logo;