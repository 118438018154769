// src/components/ExamsTable.tsx
import React from 'react';
import { Edit, Trash2 } from 'lucide-react';
import { Exam } from '../types';

interface ExamsTableProps {
  exams: Exam[];
  onDelete: (id: number) => Promise<void>;
  onEdit: (exam: Exam) => void;
}

const ExamsTable: React.FC<ExamsTableProps> = ({ exams, onDelete, onEdit }) => {
  if (!exams || !Array.isArray(exams)) {
    return <div>No exams available</div>;
  }

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden mb-8">
      <table className="w-full">
        <thead className="bg-gray-700">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Time Limit</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Pass Score</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Questions</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-700">
          {exams.map((exam) => (
            <tr key={exam.id}>
              <td className="px-6 py-4 whitespace-nowrap">{exam.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">{exam.time_limit} min</td>
              <td className="px-6 py-4 whitespace-nowrap">{exam.pass_score}%</td>
              <td className="px-6 py-4 whitespace-nowrap">{exam.questions?.length || 0}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  onClick={() => onEdit(exam)}
                  className="text-blue-400 hover:text-blue-300 mr-3"
                >
                  <Edit className="h-5 w-5" />
                </button>
                <button 
                  onClick={() => onDelete(exam.id)}
                  className="text-red-400 hover:text-red-300"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExamsTable;