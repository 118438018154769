import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Login from './components/Login';
import Registration from './components/Registration';
import UserDashboard from './components/UserDashboard';
import AdminDashboard from './components/AdminDashboard';
import ExamPage from './components/ExamPage';
import ExamResults from './components/ExamResults';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Layout>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/user-dashboard" element={
              <ProtectedRoute>
                <UserDashboard />
              </ProtectedRoute>
            } />
            <Route path="/admin-dashboard" element={
              <ProtectedRoute adminOnly>
                <AdminDashboard />
              </ProtectedRoute>
            } />
            <Route path="/exam/:id" element={
              <ProtectedRoute>
                <ExamPage />
              </ProtectedRoute>
            } />
            <Route path="/exam-results" element={
              <ProtectedRoute>
                <ExamResults />
              </ProtectedRoute>
            } />
            <Route path="/" element={<Navigate replace to="/login" />} />
          </Routes>
        </Layout>
      </AuthProvider>
    </Router>
  );
}

export default App;