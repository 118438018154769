import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Flag, Check } from 'lucide-react';
import { getExam, submitExam } from '../services/api';
import { useAuth } from '../context/AuthContext';
import { Exam, Question, ExamSubmissionData } from '../types';

const ExamPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [exam, setExam] = useState<Exam | null>(null);
  const [selectedQuestions, setSelectedQuestions] = useState<Question[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState<(number | null)[]>([]);
  const [markedQuestions, setMarkedQuestions] = useState<number[]>([]);
  const [timeLeft, setTimeLeft] = useState(0);
  const [examStarted, setExamStarted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (id) {
      fetchExam(parseInt(id));
    }
  }, [id]);

  useEffect(() => {
    if (examStarted && timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0 && examStarted) {
      handleSubmit();
    }
  }, [timeLeft, examStarted]);

  const fetchExam = async (examId: number) => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await getExam(examId);
      
      if (!response.data) {
        throw new Error('No exam data received');
      }

      const examData = response.data;
      
      // Validate exam data
      if (!examData.questions || examData.questions.length === 0) {
        throw new Error('This exam has no questions.');
      }

      if (examData.questions.length < examData.total_questions) {
        throw new Error(`Not enough questions in pool. Required: ${examData.total_questions}, Available: ${examData.questions.length}`);
      }

      // Randomly select questions based on total_questions
      const shuffled = [...examData.questions].sort(() => 0.5 - Math.random());
      const selected = shuffled.slice(0, examData.total_questions);
      
      setExam(examData);
      setSelectedQuestions(selected);
      setTimeLeft(examData.time_limit * 60);
      setUserAnswers(new Array(examData.total_questions).fill(null));

    } catch (error: any) {
      console.error('Error fetching exam:', error);
      setError(error.message || 'Failed to load exam. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartExam = () => {
    if (!exam || !selectedQuestions || selectedQuestions.length === 0) {
      setError('Cannot start exam: no questions available');
      return;
    }
    setExamStarted(true);
  };

  const handleAnswer = (answerIndex: number) => {
    const newAnswers = [...userAnswers];
    newAnswers[currentQuestion] = answerIndex;
    setUserAnswers(newAnswers);
  };

  const handleMarkQuestion = () => {
    if (!markedQuestions.includes(currentQuestion)) {
      setMarkedQuestions([...markedQuestions, currentQuestion]);
    } else {
      setMarkedQuestions(markedQuestions.filter(q => q !== currentQuestion));
    }
  };

  const handleNavigation = (direction: 'prev' | 'next') => {
    if (!selectedQuestions) return;

    if (direction === 'prev' && currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    } else if (direction === 'next' && currentQuestion < selectedQuestions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handleSubmit = async () => {
    if (!exam || !user || isSubmitting || !selectedQuestions) return;

    try {
      setIsSubmitting(true);
      
      // Filter out any questions without IDs (shouldn't happen with updated types)
      const validQuestions = selectedQuestions.filter((q): q is Question & { id: number } => 
        typeof q.id === 'number'
      );

      // Ensure we have all required question IDs
      if (validQuestions.length !== selectedQuestions.length) {
        throw new Error('Some questions are missing IDs');
      }

      // Prepare submission data with guaranteed valid IDs
      const submissionData: ExamSubmissionData = {
        userId: user.id,
        answers: userAnswers.map(answer => answer === null ? -1 : answer),
        questionIds: validQuestions.map(q => q.id)
      };

      const response = await submitExam(exam.id, submissionData);
      
      // Navigate to results page with complete result data
      navigate('/exam-results', { 
        state: {
          examName: exam.name,
          score: response.data.score,
          passed: response.data.passed,
          passScore: exam.pass_score,
          totalQuestions: selectedQuestions.length,
          correctAnswers: response.data.correct_answers,
          questions: selectedQuestions.map((q, index) => ({
            question_text: q.question_text,
            options: q.options,
            correct_answer: q.correct_answer,
            user_answer: userAnswers[index]
          }))
        },
        replace: true
      });
    } catch (error: any) {
      console.error('Error submitting exam:', error);
      setError('Failed to submit exam. Please try again.');
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 py-8 text-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mx-auto"></div>
        <p className="mt-4">Loading exam...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8 text-center">
        <div className="bg-red-600 text-white p-4 rounded-lg mb-4">
          {error}
        </div>
        <button
          onClick={() => navigate('/user-dashboard')}
          className="bg-blue-600 text-white py-2 px-6 rounded-lg text-lg hover:bg-blue-500 transition duration-300"
        >
          Return to Dashboard
        </button>
      </div>
    );
  }

  if (!exam) {
    return (
      <div className="container mx-auto px-4 py-8 text-center">
        <div className="bg-yellow-600 text-white p-4 rounded-lg mb-4">
          Exam not found
        </div>
        <button
          onClick={() => navigate('/user-dashboard')}
          className="bg-blue-600 text-white py-2 px-6 rounded-lg text-lg hover:bg-blue-500 transition duration-300"
        >
          Return to Dashboard
        </button>
      </div>
    );
  }

  if (!examStarted) {
    return (
      <div className="container mx-auto px-4 py-8 text-center">
        <h1 className="text-3xl font-bold mb-4">{exam.name}</h1>
        <p className="mb-4">This exam consists of {exam.total_questions} questions.</p>
        <p className="mb-4">You have {exam.time_limit} minutes to complete the exam.</p>
        <p className="mb-4">Pass score: {exam.pass_score}%</p>
        <p className="mb-8">Good luck!</p>
        <button
          onClick={handleStartExam}
          className="bg-blue-600 text-white py-2 px-6 rounded-lg text-lg hover:bg-blue-500 transition duration-300"
        >
          Start Exam
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8 flex justify-between items-center">
        <h1 className="text-2xl font-bold">{exam.name}</h1>
        <div className="text-xl font-semibold">
          Time Left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
        </div>
      </div>

      <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-8">
        <div className="flex justify-between mb-4">
          <h2 className="text-xl font-semibold">Question {currentQuestion + 1} of {selectedQuestions.length}</h2>
          <div className="text-sm text-gray-400">
            {userAnswers[currentQuestion] === null ? "Not answered" : "Answered"}
          </div>
        </div>
        
        <p className="text-lg mb-6">{selectedQuestions[currentQuestion].question_text}</p>
        
        <div className="space-y-4">
          {selectedQuestions[currentQuestion].options.map((option, index) => (
            <button
              key={index}
              onClick={() => handleAnswer(index)}
              className={`w-full text-left p-3 rounded-lg transition duration-200 ${
                userAnswers[currentQuestion] === index
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-700 hover:bg-gray-600'
              }`}
            >
              {option}
            </button>
          ))}
        </div>
      </div>

      <div className="flex justify-between items-center">
        <button
          onClick={() => handleNavigation('prev')}
          disabled={currentQuestion === 0}
          className="flex items-center px-4 py-2 bg-gray-700 rounded-lg disabled:opacity-50 transition duration-200"
        >
          <ChevronLeft className="mr-2" /> Previous
        </button>

        <button
          onClick={handleMarkQuestion}
          className={`px-4 py-2 rounded-lg transition duration-200 ${
            markedQuestions.includes(currentQuestion)
              ? 'bg-yellow-600 text-white'
              : 'bg-gray-700'
          }`}
        >
          <Flag className="inline-block mr-2" />
          {markedQuestions.includes(currentQuestion) ? 'Marked' : 'Mark for Review'}
        </button>

        {currentQuestion === selectedQuestions.length - 1 ? (
          <button
            onClick={handleSubmit}
            disabled={isSubmitting}
            className="flex items-center px-4 py-2 bg-green-600 rounded-lg hover:bg-green-500 disabled:opacity-50 transition duration-200"
          >
            {isSubmitting ? 'Submitting...' : 'Submit'} <Check className="ml-2" />
          </button>
        ) : (
          <button
            onClick={() => handleNavigation('next')}
            disabled={currentQuestion === selectedQuestions.length - 1}
            className="flex items-center px-4 py-2 bg-gray-700 rounded-lg disabled:opacity-50 transition duration-200"
          >
            Next <ChevronRight className="ml-2" />
          </button>
        )}
      </div>

      {markedQuestions.length > 0 && (
        <div className="mt-8 p-4 bg-gray-800 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Marked Questions:</h3>
          <div className="flex flex-wrap gap-2">
            {markedQuestions.map((questionIndex) => (
              <button
                key={questionIndex}
                onClick={() => setCurrentQuestion(questionIndex)}
                className="px-3 py-1 bg-yellow-600 rounded hover:bg-yellow-500 transition duration-200"
              >
                Question {questionIndex + 1}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamPage;