import React from 'react';
import { X } from 'lucide-react';
import { UserListItem, DetailedUserStats } from '../types';

interface UserStatsModalProps {
  user: UserListItem;
  stats: DetailedUserStats;
  onClose: () => void;
}

const UserStatsModal: React.FC<UserStatsModalProps> = ({ user, stats, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 rounded-lg p-6 w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">User Statistics: {user.name}</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
          <div className="bg-gray-700 p-4 rounded-lg">
            <h3 className="text-sm font-medium text-gray-400">Exams Taken</h3>
            <p className="text-2xl font-bold">{stats.examsTaken}</p>
          </div>
          <div className="bg-gray-700 p-4 rounded-lg">
            <h3 className="text-sm font-medium text-gray-400">Average Score</h3>
            <p className="text-2xl font-bold text-blue-400">{stats.averageScore.toFixed(1)}%</p>
          </div>
          <div className="bg-gray-700 p-4 rounded-lg">
            <h3 className="text-sm font-medium text-gray-400">Highest Score</h3>
            <p className="text-2xl font-bold text-green-400">{stats.highestScore.toFixed(1)}%</p>
          </div>
          <div className="bg-gray-700 p-4 rounded-lg">
            <h3 className="text-sm font-medium text-gray-400">Lowest Score</h3>
            <p className="text-2xl font-bold text-red-400">{stats.lowestScore.toFixed(1)}%</p>
          </div>
        </div>

        <h3 className="text-xl font-semibold mb-4">Exam History</h3>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-700">
              <tr>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Exam Name</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Score</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Date</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {stats.examHistory.map((exam, index) => (
                <tr key={index}>
                  <td className="px-4 py-3 whitespace-nowrap">{exam.examName}</td>
                  <td className="px-4 py-3 whitespace-nowrap">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      exam.score >= 70 ? 'bg-green-500/20 text-green-300' :
                      exam.score >= 60 ? 'bg-yellow-500/20 text-yellow-300' :
                      'bg-red-500/20 text-red-300'
                    }`}>
                      {exam.score.toFixed(1)}%
                    </span>
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-gray-300">
                    {new Date(exam.completedAt).toLocaleDateString()}
                  </td>
                </tr>
              ))}
              {stats.examHistory.length === 0 && (
                <tr>
                  <td colSpan={3} className="px-4 py-3 text-center text-gray-400">
                    No exam history available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserStatsModal;