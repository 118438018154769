// src/components/CourseViewer.tsx
import React, { useState, useEffect } from 'react';
import { FileText, Video, Music, File, ChevronRight, ChevronLeft } from 'lucide-react';
import { Course, Module } from '../types';

interface CourseViewerProps {
  course: Course;
  onClose: () => void;
}

const CourseViewer: React.FC<CourseViewerProps> = ({ course, onClose }) => {
  const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const currentModule = course.modules[currentModuleIndex];

  const handleModuleChange = (direction: 'next' | 'prev') => {
    if (direction === 'next' && currentModuleIndex < course.modules.length - 1) {
      setCurrentModuleIndex(currentModuleIndex + 1);
    } else if (direction === 'prev' && currentModuleIndex > 0) {
      setCurrentModuleIndex(currentModuleIndex - 1);
    }
  };

  const getModuleIcon = (contentType: string) => {
    switch (true) {
      case contentType.startsWith('video'):
        return <Video className="h-5 w-5" />;
      case contentType.startsWith('audio'):
        return <Music className="h-5 w-5" />;
      case contentType === 'application/pdf':
        return <FileText className="h-5 w-5" />;
      default:
        return <File className="h-5 w-5" />;
    }
  };

  const renderContent = (module: Module) => {
    switch (module.content_type) {
      case 'video/mp4':
      case 'video/webm':
        return (
          <div className="aspect-w-16 aspect-h-9">
            <video 
              className="w-full rounded-lg" 
              controls
              controlsList="nodownload"
              src={module.file_path}
            >
              Your browser does not support video playback.
            </video>
          </div>
        );
      
      case 'audio/mpeg':
      case 'audio/wav':
        return (
          <div className="p-4 bg-gray-700 rounded-lg">
            <audio 
              className="w-full" 
              controls
              controlsList="nodownload"
              src={module.file_path}
            >
              Your browser does not support audio playback.
            </audio>
          </div>
        );
      
      case 'application/pdf':
        return (
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(module.file_path)}&embedded=true`}
            className="w-full h-[600px] rounded-lg"
            title={module.title}
          />
        );
      
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(module.file_path)}`}
            className="w-full h-[600px] rounded-lg"
            title={module.title}
          />
        );
      
      default:
        return (
          <div className="flex items-center justify-center h-64 bg-gray-700 rounded-lg">
            <div className="text-center">
              <File className="w-16 h-16 text-gray-400 mx-auto mb-2" />
              <p>Preview not available</p>
              <a 
                href={module.file_path}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-300 mt-2 inline-block"
              >
                Download File
              </a>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <div className="mb-6 flex justify-between items-center">
          <h1 className="text-2xl font-bold">{course.title}</h1>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
          >
            Close
          </button>
        </div>

        <div className="grid grid-cols-4 gap-6">
          {/* Module List */}
          <div className="col-span-1 bg-gray-800 rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-4">Modules</h2>
            <div className="space-y-2">
              {course.modules.map((module, index) => (
                <button
                  key={module.id}
                  onClick={() => setCurrentModuleIndex(index)}
                  className={`w-full text-left p-3 rounded-lg flex items-center ${
                    currentModuleIndex === index
                      ? 'bg-blue-600'
                      : 'bg-gray-700 hover:bg-gray-600'
                  }`}
                >
                  {getModuleIcon(module.content_type)}
                  <span className="ml-2 truncate">{module.title}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Content Viewer */}
          <div className="col-span-3 bg-gray-800 rounded-lg p-6">
            {error ? (
              <div className="bg-red-500/20 border border-red-500 p-4 rounded-lg">
                {error}
              </div>
            ) : (
              <>
                <h2 className="text-xl font-semibold mb-4">{currentModule.title}</h2>
                {isLoading ? (
                  <div className="flex items-center justify-center h-64">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
                  </div>
                ) : (
                  renderContent(currentModule)
                )}
                
                {/* Navigation */}
                <div className="flex justify-between mt-6">
                  <button
                    onClick={() => handleModuleChange('prev')}
                    disabled={currentModuleIndex === 0}
                    className="px-4 py-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
                  >
                    <ChevronLeft className="mr-2" /> Previous
                  </button>
                  <button
                    onClick={() => handleModuleChange('next')}
                    disabled={currentModuleIndex === course.modules.length - 1}
                    className="px-4 py-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
                  >
                    Next <ChevronRight className="ml-2" />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseViewer;