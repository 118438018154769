import React, { useState, useEffect } from 'react';
import { PlusCircle, Upload, Users } from 'lucide-react';
import { getExams, getCourses, createExam, createCourse, deleteExam, deleteCourse, updateExam } from '../services/api';
import AddExamForm from './AddExamForm';
import EditExamForm from './EditExamForm';
import AddCourseForm from './AddCourseForm';
import ExamsTable from './ExamsTable';
import CoursesTable from './CoursesTable';
import UserManagement from './UserManagement';
import { Exam, Course, NewExam } from '../types';
import Logo from './Logo';

const AdminDashboard: React.FC = () => {
  const [exams, setExams] = useState<Exam[]>([]);
  const [courses, setCourses] = useState<Course[]>([]);
  const [showAddExamForm, setShowAddExamForm] = useState(false);
  const [showAddCourseForm, setShowAddCourseForm] = useState(false);
  const [examToEdit, setExamToEdit] = useState<Exam | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<'exams' | 'courses' | 'users'>('exams');

  useEffect(() => {
    fetchExams();
    fetchCourses();
  }, []);

  const fetchExams = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await getExams();
      setExams(response.data || []);
    } catch (error: any) {
      console.error('Error fetching exams:', error);
      setError('Failed to load exams. Please try again later.');
      setExams([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCourses = async () => {
    try {
      setIsLoading(true);
      const response = await getCourses();
      setCourses(response.data || []);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setCourses([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddExam = async (newExam: NewExam) => {
    try {
      setError(null);
      const response = await createExam(newExam);
      const createdExam = response.data.exam;
      
      if (createdExam) {
        setExams(prevExams => [...prevExams, createdExam]);
        setShowAddExamForm(false);
      } else {
        throw new Error('No exam data received from server');
      }
    } catch (error: any) {
      console.error('Error creating exam:', error);
      setError(error.response?.data?.message || 'Failed to create exam. Please try again.');
    }
  };

  const handleEditExam = async (examId: number, updatedExam: NewExam) => {
    try {
      setError(null);
      const response = await updateExam(examId, updatedExam);
      const updatedExamData = response.data.exam;
      
      setExams(prevExams => 
        prevExams.map(exam => 
          exam.id === examId ? updatedExamData : exam
        )
      );
      setExamToEdit(null);
    } catch (error: any) {
      console.error('Error updating exam:', error);
      setError(error.response?.data?.message || 'Failed to update exam. Please try again.');
    }
  };

  const handleAddCourse = async (formData: FormData) => {
    try {
      setError(null);
      const response = await createCourse(formData);
      
      if (response.data.course) {
        setCourses(prevCourses => [...prevCourses, response.data.course]);
        setShowAddCourseForm(false);
      } else {
        throw new Error('No course data received from server');
      }
    } catch (error: any) {
      console.error('Error creating course:', error);
      setError(error.response?.data?.message || 'Failed to create course. Please try again.');
    }
  };

  const handleDeleteExam = async (examId: number) => {
    if (!window.confirm('Are you sure you want to delete this exam?')) return;

    try {
      await deleteExam(examId);
      setExams(prevExams => prevExams.filter(exam => exam.id !== examId));
    } catch (error) {
      console.error('Error deleting exam:', error);
      setError('Failed to delete exam. Please try again.');
    }
  };

  const handleDeleteCourse = async (courseId: number) => {
    if (!window.confirm('Are you sure you want to delete this course?')) return;

    try {
      await deleteCourse(courseId);
      setCourses(prevCourses => prevCourses.filter(course => course.id !== courseId));
    } catch (error) {
      console.error('Error deleting course:', error);
      setError('Failed to delete course. Please try again.');
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'exams':
        return (
          <>
            <div className="mb-6 flex space-x-4">
              <button
                onClick={() => setShowAddExamForm(true)}
                className="bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-500 transition duration-300 flex items-center"
              >
                <PlusCircle className="mr-2" /> Add New Exam
              </button>
            </div>

            {showAddExamForm && (
              <AddExamForm
                onSubmit={handleAddExam}
                onCancel={() => setShowAddExamForm(false)}
              />
            )}

            {examToEdit && (
              <EditExamForm
                exam={examToEdit}
                onSubmit={handleEditExam}
                onCancel={() => setExamToEdit(null)}
              />
            )}

            <h2 className="text-2xl font-semibold mb-4">Exams</h2>
            {isLoading ? (
              <div className="text-center py-4">Loading exams...</div>
            ) : (
              <ExamsTable 
                exams={exams}
                onDelete={handleDeleteExam}
                onEdit={setExamToEdit}
              />
            )}
          </>
        );

      case 'courses':
        return (
          <>
            <div className="mb-6">
              <button
                onClick={() => setShowAddCourseForm(true)}
                className="bg-purple-600 text-white py-2 px-4 rounded-lg hover:bg-purple-500 transition duration-300 flex items-center"
              >
                <Upload className="mr-2" /> Upload New Course
              </button>
            </div>

            {showAddCourseForm && (
              <AddCourseForm
                onSubmit={handleAddCourse}
                onCancel={() => setShowAddCourseForm(false)}
              />
            )}

            <h2 className="text-2xl font-semibold mb-4">Uploaded Courses</h2>
            <CoursesTable 
              courses={courses}
              onDelete={handleDeleteCourse}
            />
          </>
        );

      case 'users':
        return (
          <>
            <h2 className="text-2xl font-semibold mb-4">User Management</h2>
            <UserManagement />
          </>
        );
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center justify-between mb-8">
        <Logo className="h-12" />
        <div>
          <h1 className="text-3xl font-bold text-blue-400" style={{ fontFamily: 'Orbitron, sans-serif' }}>
            Mascott Learning Hub
          </h1>
          <h2 className="text-xl text-gray-400 mt-2">Admin Dashboard</h2>
        </div>
      </div>
      
      {error && (
        <div className="bg-red-500 text-white p-4 rounded-lg mb-4">
          {error}
        </div>
      )}

      <div className="mb-6">
        <div className="border-b border-gray-700">
          <nav className="flex space-x-8">
            <button
              onClick={() => setActiveTab('exams')}
              className={`py-4 px-1 relative ${
                activeTab === 'exams'
                  ? 'text-blue-400 border-b-2 border-blue-400'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
            >
              Exams
            </button>
            <button
              onClick={() => setActiveTab('courses')}
              className={`py-4 px-1 relative ${
                activeTab === 'courses'
                  ? 'text-blue-400 border-b-2 border-blue-400'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
            >
              Courses
            </button>
            <button
              onClick={() => setActiveTab('users')}
              className={`py-4 px-1 relative ${
                activeTab === 'users'
                  ? 'text-blue-400 border-b-2 border-blue-400'
                  : 'text-gray-400 hover:text-gray-300'
              } flex items-center`}
            >
              <Users className="mr-2 h-5 w-5" />
              Users
            </button>
          </nav>
        </div>
      </div>

      {renderTabContent()}
    </div>
  );
};

export default AdminDashboard;