import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Mail, Lock, User, AlertTriangle } from 'lucide-react';
import { useAuth } from '../context/AuthContext';

const ADMIN_SECRET = 'Mascottec31415926!';
const EMAIL_DOMAIN = '@mascottec.com';

const Registration: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminCode, setAdminCode] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  
  const navigate = useNavigate();
  const { register } = useAuth();

  const validateEmail = (email: string): boolean => {
    if (!email.toLowerCase().endsWith(EMAIL_DOMAIN)) {
      setEmailError(`Registration is limited to Mascott Equipment employees (${EMAIL_DOMAIN} email addresses only)`);
      return false;
    }
    setEmailError('');
    return true;
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (newEmail) {
      validateEmail(newEmail);
    } else {
      setEmailError('');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    // Validate email domain
    if (!validateEmail(email)) {
      return;
    }

    // Verify admin code if attempting admin registration
    const shouldBeAdmin = isAdmin && adminCode === ADMIN_SECRET;
    if (isAdmin && adminCode !== ADMIN_SECRET) {
      setError('Invalid admin code');
      return;
    }

    try {
      // Pass the validated admin status
      await register(name, email, password, shouldBeAdmin);
      navigate(shouldBeAdmin ? '/admin-dashboard' : '/user-dashboard');
    } catch (error: any) {
      if (error.response?.data?.message) {
        setError(error.response.data.message);
      } else {
        setError('Registration failed. Please try again.');
      }
      console.error('Registration error:', error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <div className="p-8 bg-gray-800 rounded-lg shadow-md w-96">
        <h2 className="text-2xl font-bold mb-6 text-center text-blue-400">
          Register for Exam Platform
        </h2>
        
        {error && (
          <div className="mb-4 p-4 bg-red-600/20 border border-red-500 rounded-md text-sm flex items-center">
            <AlertTriangle className="h-5 w-5 mr-2 flex-shrink-0" />
            <span>{error}</span>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="name">
              Full Name
            </label>
            <div className="relative">
              <input
                type="text"
                id="name"
                className="w-full p-2 pl-10 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
                placeholder="John Doe"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <User className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="email">
              Email
            </label>
            <div className="relative">
              <input
                type="email"
                id="email"
                className={`w-full p-2 pl-10 bg-gray-700 border rounded-md focus:outline-none ${
                  emailError ? 'border-red-500' : 'border-gray-600 focus:border-blue-500'
                }`}
                placeholder="your.email@mascottec.com"
                value={email}
                onChange={handleEmailChange}
                required
              />
              <Mail className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            {emailError && (
              <p className="mt-1 text-sm text-red-400 flex items-center">
                <AlertTriangle className="h-4 w-4 mr-1" />
                {emailError}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="password">
              Password
            </label>
            <div className="relative">
              <input
                type="password"
                id="password"
                className="w-full p-2 pl-10 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                minLength={8}
              />
              <Lock className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>

          <div className="mb-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isAdmin}
                onChange={(e) => {
                  setIsAdmin(e.target.checked);
                  if (!e.target.checked) {
                    setAdminCode(''); // Clear admin code when unchecking
                  }
                }}
                className="form-checkbox h-4 w-4 text-blue-600 bg-gray-700 border-gray-600 rounded focus:ring-blue-500"
              />
              <span className="text-sm font-medium">Register as Admin</span>
            </label>
          </div>

          {isAdmin && (
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2" htmlFor="adminCode">
                Admin Code
              </label>
              <input
                type="password"
                id="adminCode"
                className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Enter admin code"
                value={adminCode}
                onChange={(e) => setAdminCode(e.target.value)}
                required
              />
            </div>
          )}

          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mb-4 transition-colors"
          >
            Register
          </button>
        </form>

        <p className="text-center text-gray-400">
          Already have an account?{' '}
          <Link to="/login" className="text-blue-400 hover:underline">
            Login here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Registration;